#star-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Allows clicks to pass through */
}

.star {
  position: absolute;
  background-color: yellow;
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
  opacity: 0.8;
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}

.circle {
  position: absolute;
  background-color: yellow;
  border-radius: 50%;
  opacity: 0.8;
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}

