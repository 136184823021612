.typing-wrapper {
  height: 100vh;
  display: grid;
  place-items: center;
  background-color: black;
}

.typing-intro {
  width: 20ch;
  animation: typing 2s steps(22), blink 0.6s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 2em;
  color: white;
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}
