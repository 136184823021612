.projects-container {
  display: 'flex';
  align-items: center;
  justify-content: center;
  align-content: center;
  min-height: 100vh;
  height: 100%;
}

.project-cards-container {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  row-gap: 30px;
}

@media only screen and (max-width: 600px) {
  .css-vd262g-MuiGrid-root {
    margin-top: 10px;
  }
}

.project-card-container {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
