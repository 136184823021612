.fade-enter {
  opacity: 0;
  transform: translateZ(0); /* Promote layer creation */
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 2000ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-out;
}

.home-profile-container {
  height: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-profile-avatar {
  margin-bottom: 3%;
  box-shadow: 0 0 20px #ffffffdb, -10px 0 50px #55bfce, 10px 0 50px #ff2b14;
  transition: box-shadow 500ms ease;
}

.home-profile-avatar:hover {
  box-shadow: 0 0 30px #ffffff, -10px 0 70px #00f2ff, 10px 0 70px #e603ff;
}

.main-component {
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
}

.text-align-center {
  text-align: center;
}

.font-georgia-serif {
  font-family: Georgia, serif;
}
