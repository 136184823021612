.remove-tags-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 10px;
  column-gap: 5px;
  row-gap: 5px;

  @media (max-width: 600px) {
    margin-top: 10px;
  }
}
